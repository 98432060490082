.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 10px;
    // box-shadow: 0px -6.8px 9.8px rgba(0, 0, 0, 0.003),
    //     0px -10.8px 23.5px rgba(0, 0, 0, 0.008),
    //     0px -10.3px 44.3px rgba(0, 0, 0, 0.016),
    //     0px -1.5px 79.1px rgba(0, 0, 0, 0.026),
    //     0px 28.5px 147.9px rgba(0, 0, 0, 0.042),
    //     0px 174px 354px rgba(0, 0, 0, 0.07);
}

.carousel.carousel-slider {
    box-shadow: 8px 11px 15px 0px #0606067a;
    overflow: initial;
}

.react-carousel {
    flex: 1 1 auto;
}

.react-carousel-slide {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.carousel {
    height: 100%;
}

.carousel .control-dots {
    bottom: -50px;
}

.carousel .control-dots .dot {
    box-shadow: none;
    background: var(--clr-yellow);
    width: 16px;
    height: 16px;
}

.carousel .slide img {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
}

.image-wrapper {
    width: 100%;
    height: auto;
}
