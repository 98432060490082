.contact-form {
    // box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    background: var(--clr-white);
    padding: 2.5rem;
    margin-top: 6rem;
    max-width: 80rem;
    text-align: right;
    width: 95%;
    border-radius: 5px;
    margin: 2.5rem auto 0 auto;

    &-wrapper {
        text-align: left;

        .form-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.563rem;

            label {
                color: #666;
                font-size: 1.375rem;
                font-family: "Lato", sans-serif;
                font-weight: 500;
                margin-bottom: 1rem;
                display: block;
                text-align: left;
            }

            input {
                color: #333;
                padding: 1.25rem;
                width: 100%;
                border: 1px solid #ebebeb;
                font-size: 1rem;
                letter-spacing: 0;
                background: #f0f0f0;
                border-radius: 5px;
                font-weight: 600;
            }

            textarea {
                border: 1px solid #ebebeb;
                color: #333;
                font-size: 1rem;
                font-family: "Lato", sans-serif;
                font-weight: 600;
                padding: 1.25rem;
                width: 100%;
                letter-spacing: 0;
                background: #f0f0f0;
                border-radius: 5px;
            }
        }

        .form-textarea {
            text-align: center;

            button {
                min-width: 30%;
            }
        }

        .box-success-message {
            text-align: center;

            .success-message {
                margin-top: 1.25rem;
                font-size: 1.125rem;
                background-color: #008000;
                display: inline-block;
                text-align: center;
                color: var(--clr-white);
                padding: 0.313rem 0.938rem;
            }
        }
    }
}
