.design {
    background-color: var(--clr-black);
    padding: 5rem 0;
    color: var(--clr-white);
    position: relative;

    &-wrapper {
        padding: 0 0.938rem;

        @include media(">mini-tablet") {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding: 0;
            gap: 0.625rem;

            &::after {
                content: "";
                background-color: var(--clr-white);
                width: 5rem;
                height: 100%;
                position: absolute;
                left: 0;
                gap: 0.625rem;

                @include media(">desktop-small") {
                    width: 6.25rem;
                }
            }
        }

        p {
            color: var(--clr-white);
        }

        &-col-a {
            @include media(">mini-tablet") {
                flex: 0 1 30%;
            }

            &-inside {
                @include media(">mini-tablet") {
                    width: 90%;
                    margin: 0 auto;
                }

                h2 {
                }

                ul {
                    margin-bottom: 3.125rem;
                    li {
                        position: relative;
                        font-size: 1.625rem;
                    }
                }

                .de-line {
                    position: absolute;
                    left: -35px;
                    animation: mymove 5s infinite;
                    animation-timing-function: ease;
                }

                a {
                    margin-bottom: 3.125rem;
                }
            }
        }

        &-col-b {
            @include media(">mini-tablet") {
                flex: 0 1 60%;
            }
        }
    }
}

@keyframes mymove {
    0% {
        left: -35px;
    }
    50% {
        left: -25px;
    }
    100% {
        left: -35px;
    }
}
