.bim {
    background-color: var(--clr-white);
    color: var(--clr-black);
    padding: 3.125rem 0;
    position: relative;

    @include media(">phone-small") {
        padding: 5rem 0;
    }

    @include media(">mini-tablet") {
        background-color: var(--clr-black);
        color: var(--clr-white);
    }

    &-wrapper {
        padding: 0 0.938rem;

        @include media(">mini-tablet") {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding: 0;
            gap: 0.625rem;
        }

        &::after {
            @include media(">mini-tablet") {
                content: "";
                background-color: var(--clr-white);
                width: 5rem;
                height: 100%;
                position: absolute;
                left: 0;

                @include media(">desktop-small") {
                    width: 6.25rem;
                }
            }
        }

        p {
            color: var(--clr-black);

            @include media(">mini-tablet") {
                color: var(--clr-white);
            }
        }

        &-col-a {
            @include media(">mini-tablet") {
                flex: 0 1 30%;
            }

            &-inside {
                @include media(">mini-tablet") {
                    width: 90%;
                    margin: 0 auto;
                }
            }
        }

        &-col-b {
            @include media(">mini-tablet") {
                flex: 0 1 60%;
            }

            &-wrapper {
                display: flex;
                flex-direction: row;
                gap: 0.938rem;
                @include media(">mini-tablet") {
                    gap: 1.875rem;
                }

                &-item {
                    transition: margin-top 1s ease-in-out;

                    &:hover {
                        margin-top: -15px;
                    }
                    img {
                        margin-bottom: 1.563rem;
                        &:hover {
                            box-shadow: 3px 2px 15px 7px #fbf8f842;
                        }

                        @include media(">mini-tablet") {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
