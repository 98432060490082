/*
=============== 
Navbar
===============
*/
/* add :not(.nav-logo) to img */

.navbar {
    display: flex;
    height: 5rem;
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 2;
    background: linear-gradient(
        to right,
        var(--clr-white) 0%,
        var(--clr-white) 80%,
        var(--clr-black) 100%,
        var(--clr-black) 100%
    );

    @include media(">mini-tablet") {
        display: flex;
        align-items: center;
        position: fixed;
        background: linear-gradient(
            to right,
            var(--clr-white) 0%,
            var(--clr-white) 80%,
            var(--clr-black) 95%,
            var(--clr-black) 100%
        );
    }

    @include media(">desktop-small") {
        background: linear-gradient(
            to right,
            var(--clr-white) 0%,
            var(--clr-white) 60%,
            var(--clr-black) 60%,
            var(--clr-black) 100%
        );
    }
}

.navBorder {
    @include media(">desktop-small") {
        position: relative;

        &::before {
            content: "";
            width: 40%;
            height: 1px;
            right: 0;
            top: 40px;
            position: absolute;
            background-color: var(--clr-white);
            z-index: 3;
        }

        &::after {
            content: "";
            width: 1px;
            height: 40px;
            right: 0px;
            bottom: 0;
            position: absolute;
            background-color: var(--clr-white);
            z-index: 3;
        }
    }
}

.nav-center {
    height: 100%;
    max-width: 90vw;
    margin: 0 auto;
    width: 90vw;

    @include media(">mini-tablet") {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* optional */
        // flex-wrap: wrap;
    }
}

.nav-header {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    @include media(">mini-tablet") {
        flex: 0 1 10%;
        padding: 0;
    }

    .nav-logo {
        width: 4.688rem;
        height: auto;

        @include media(">mini-tablet") {
            width: 8.125rem;
        }
    }
}

.nav-toggle {
    background: transparent;
    border: none;
    outline: none;
    font-size: 1.5rem;
    color: var(--clr-yellow);
    cursor: pointer;
    transition: var(--transition);

    @include media(">mini-tablet") {
        display: none;
    }
}
.nav-toggle:hover {
    transform: scale(1.2);
}
.nav-link {
    font-size: 1rem;
    font-family: "Rubik", sans-serif;
    display: block;
    padding: 1rem 1.125rem;
    text-transform: capitalize;
    letter-spacing: var(--spacing);
    transition: var(--transition);
    color: var(--clr-black);
    cursor: pointer;
    text-transform: uppercase;

    :hover {
        color: var(--clr-primary-1);
        background: var(--clr-primary-8);
        padding-left: 2.25rem;
    }

    @include media(">mini-tablet") {
        &:hover {
            padding: 1rem 1.125rem;
            color: var(--clr-yellow-2);
            background: transparent;
            cursor: pointer;
        }
    }
}

/* nav toggle functionality */
.nav-links {
    height: 0;
    overflow: hidden;
    // transition: var(--transition);

    @include media(">mini-tablet") {
        height: auto;
        display: flex;
    }

    li {
        cursor: pointer;
    }
}

.nav-mobile {
    display: block;
    height: 100vh;
    display: block;
    background-color: var(--clr-yellow);
    width: 100%;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
}
.show-links {
    height: 280px;
}

.nav-icons {
    position: fixed;
    right: 0;
    top: 25rem;
    bottom: 0;
    z-index: 3;
    padding: 20px;
    background-color: var(--clr-black);
    height: 7.188rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

    @include media(">mini-tablet") {
        left: 0;
        top: 0;
        right: unset;
        margin-top: auto;
        margin-bottom: auto;
        height: 8.75rem;
        padding: 20px 0 20px 10px;
    }
    &-wrapper {
        display: flex;
        flex-direction: column;

        @include media(">mini-tablet") {
            align-items: end;
            border-top: 1px solid #fff;
            border-left: 1px solid #fff;
            border-bottom: 1px solid #fff;
            height: 100%;
            padding: 10px 15px 10px 10px;
            position: relative;
        }
    }
}

.nav-icon {
    color: var(--clr-yellow);
    font-size: 1.2rem;
    transition: transform 1s;

    .fab {
        font-size: 35px;
        transition: transform 1s;
    }

    .fa {
        font-size: 30px;
        transition: transform 1s;
    }
}
.nav-icon:hover {
    color: var(--clr-yellow-2);
    cursor: pointer;

    .fab {
        transform: scale(1.2);
        cursor: pointer;
    }

    .fa {
        transform: scale(1.2);
        cursor: pointer;
    }
}
