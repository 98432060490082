.construction {
    background-color: var(--clr-black);
    color: var(--clr-white);
    padding: 3.125rem 0;
    position: relative;

    @include media(">phone-small") {
        padding: 5rem 0;
    }

    @include media(">mini-tablet") {
        background-color: var(--clr-black);
        color: var(--clr-white);
    }

    &-wrapper {
        padding: 0 0.938rem;

        @include media(">mini-tablet") {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding: 0;
            gap: 0.625rem;
        }

        &::after {
            @include media(">mini-tablet") {
                content: "";
                background-color: var(--clr-white);
                width: 5rem;
                height: 100%;
                position: absolute;
                left: 0;

                @include media(">desktop-small") {
                    width: 6.25rem;
                }
            }
        }

        p {
            color: var(--clr-white);
        }

        &-col-a {
            @include media(">mini-tablet") {
                flex: 0 1 30%;
            }

            &-inside {
                @include media(">mini-tablet") {
                    width: 90%;
                    margin: 0 auto;
                }
            }
        }

        &-col-b {
            @include media(">mini-tablet") {
                flex: 0 1 60%;
            }

            &-wrapper {
                display: grid;
                grid-template-areas:
                    "col-a col-b col-c"
                    "col-d col-d col-c";
                gap: 1.25rem;

                &-item {
                    overflow: hidden;
                    img {
                        height: 100%;

                        &:hover {
                            box-shadow: 3px 2px 15px 7px #fbf8f842;
                            transform: scale(1.2);
                        }
                        @include media(">mini-tablet") {
                            margin-bottom: 0;
                        }
                    }
                }

                .col-a {
                    grid-area: col-a;
                }

                .col-b {
                    grid-area: col-b;
                }

                .col-c {
                    grid-area: col-c;
                }

                .col-d {
                    grid-area: col-d;

                    img {
                        height: 100%;
                    }
                }
            }
        }
    }
}
