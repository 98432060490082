.hero {
    margin-top: 7.5rem;
    padding-bottom: 2.5rem;

    @include media(">mini-tablet") {
        margin-top: 5rem;
        background: linear-gradient(
            to right,
            var(--clr-white) 0%,
            var(--clr-white) 55%,
            var(--clr-black) 55%,
            var(--clr-black) 100%
        );
    }

    @include media(">desktop-small") {
        background: linear-gradient(
            to right,
            var(--clr-white) 0%,
            var(--clr-white) 60%,
            var(--clr-black) 60%,
            var(--clr-black) 100%
        );
    }

    &-wrapper {
        @include media(">mini-tablet") {
            display: flex;
            align-items: center;
            padding: 0.938rem 3.125rem 3.125rem 3.125rem;
            gap: 1.563rem;
            position: relative;

            @include media(">desktop-small") {
                border-bottom: 1px solid var(--clr-white);
                border-right: 1px solid var(--clr-white);
                height: 100%;
            }

            @include media(">desktop-large") {
                padding: 0.5rem 3.125rem 3.7rem 3.125rem;
            }
        }

        &-container-img {
            margin-bottom: 5rem;

            @include media(">mini-tablet") {
                flex: 0 1 70%;
                margin-bottom: 0;
            }

            img {
                margin-bottom: 1.563rem;

                @include media(">mini-tablet") {
                    box-shadow: 3px 2px 15px 2px #0606067a;
                }
            }
        }

        &-container-info {
            @include media(">mini-tablet") {
                flex: 0 1 30%;
                text-align: right;
            }

            .heading-h1 {
                transition: transform 1s;

                &:hover {
                    transform: rotate(-3deg);
                }

                @include media(">mini-tablet") {
                    color: var(--clr-white);
                    text-align: right;
                    font-size: 2.125rem;
                }

                @include media(">desktop-medium") {
                    color: var(--clr-white);
                    text-align: right;
                    font-size: 2.5rem;
                }
            }

            p {
                color: var(--clr-black);

                @include media(">mini-tablet") {
                    color: var(--clr-white);
                }
            }
        }
    }
}
