.contact {
    // background: url("../../images/hero.webp") center/cover no-repeat;
    position: relative;
    &-wrapper {
        text-align: center;
        @include media(">mini-tablet") {
            &::after {
                @include media(">mini-tablet") {
                    content: "";
                    background-color: var(--clr-black);
                    width: 5rem;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;

                    @include media(">desktop-small") {
                        width: 6.25rem;
                    }
                }
            }
        }
    }
}
