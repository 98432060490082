.special-number {
    font-size: 3.75rem;
    font-family: "Alfa Slab One", cursive;
    position: absolute;
    top: -1.25rem;
    right: 0.625rem;
    color: var(--clr-white);
    opacity: 0.2;

    &:hover {
        text-shadow: 11px 14px 19px #fdfbfb;
    }

    @include media(">mini-tablet") {
        font-size: 3.5rem;
        top: 0;
        right: unset;
        left: calc(12% - 20px);
    }

    @include media(">desktop-large") {
        font-size: 7.5rem;
        top: 0;
        right: unset;
        left: 200px;
    }
}
