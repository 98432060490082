/*
=============== 
Variables
===============
*/

$softBlue: #2caebab3;
$softBlack: #000000b3;

:root {
    /* dark shades of primary color*/
    --clr-primary-1: hsl(184, 91%, 17%);
    --clr-primary-2: hsl(185, 84%, 25%);
    --clr-primary-3: hsl(185, 81%, 29%);
    --clr-primary-4: hsl(184, 77%, 34%);
    /* primary/main color */
    --clr-primary-5: hsl(185, 62%, 45%);
    /* lighter shades of primary color */
    --clr-primary-6: hsl(185, 57%, 50%);
    --clr-primary-7: hsl(184, 65%, 59%);
    --clr-primary-8: hsl(184, 80%, 74%);
    --clr-primary-9: hsl(185, 94%, 87%);
    --clr-primary-10: hsl(186, 100%, 94%);
    /* darkest grey - used for headings */
    --clr-grey-1: hsl(209, 61%, 16%);
    --clr-grey-2: hsl(211, 39%, 23%);
    --clr-grey-3: hsl(209, 34%, 30%);
    --clr-grey-4: hsl(209, 28%, 39%);
    /* grey used for paragraphs */
    --clr-grey-5: hsl(210, 22%, 49%);
    --clr-grey-6: hsl(209, 23%, 60%);
    --clr-grey-7: hsl(211, 27%, 70%);
    --clr-grey-8: hsl(210, 31%, 80%);
    --clr-grey-9: hsl(212, 33%, 89%);
    --clr-grey-10: hsl(210, 36%, 96%);
    --clr-white: #fff;
    --clr-black: #1b2626;
    --clr-yellow: #facf0f;
    --clr-yellow-2: #cdad20;
    --ff-primary: "Lato", sans-serif;
    --transition: all 0.3s linear;
    --spacing: 0.25rem;
    --radius: 0.5rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
/*
=============== 
Global Styles
===============
*/

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: "Raleway", sans-serif;
    background: var(--clr-white);
    color: var(--clr-grey-1);
    line-height: 1.5;
    font-size: 0.875rem;
}
ul {
    list-style-type: none;
    font-family: "Raleway", sans-serif;
}

li {
    font-size: 1.25rem;
}

a {
    text-decoration: none;
    color: var(--clr-yellow-2);

    &:hover {
        cursor: pointer;
    }
}
img:not(.nav-logo) {
    width: 100%;
    display: block;
}

h1,
h2,
h3,
h4 {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    letter-spacing: var(--spacing);
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: 0.188rem;
    margin-bottom: 0.75rem;
}
h1 {
    font-size: 1.875rem;

    @include media(">mini-tablet") {
        font-size: 3rem;
    }
}
h2 {
    font-size: 2rem;
}
h3 {
    font-size: 1.25rem;
}
h4 {
    font-size: 1rem;
}
p {
    margin-bottom: 1.25rem;
    color: var(--clr-black);
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 1.375rem;
}

.paragraph-center {
    text-align: center;
}

.text-white {
    color: var(--clr-white);
}

.yellow-color {
    color: var(--clr-yellow);
}

.text-rot {
    transition: transform 1s;

    &:hover {
        transform: rotate(3deg);
    }
}

@media screen and (min-width: 992px) {
    h1 {
        font-size: 2.125rem;
    }
    h2 {
        font-size: 2.125rem;
    }
    h3 {
        font-size: 1.75rem;
    }
    h4 {
        font-size: 1.25rem;
    }
    body {
        font-size: 1rem;
    }
    h1,
    h2,
    h3,
    h4 {
        line-height: 1;
    }
}
/*  global classes */

.btn {
    text-transform: uppercase;
    background: var(--clr-yellow);
    color: var(--clr-black);
    padding: 0.75rem 0.75rem;
    letter-spacing: var(--spacing);
    display: inline-block;
    font-weight: 700;
    transition: var(--transition);
    font-size: 1rem;
    border: 2px solid transparent;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: transform 1s;
}
.btn:hover {
    color: var(--clr-black);
    background: var(--clr-yellow-2);
    transform: scale(1.2);
}
/* section */
.section {
    padding: 5rem 0;
    scroll-margin-top: 4rem;
}
.section-title {
    text-align: center;
    margin-bottom: 4rem;
}
.section-title h2 {
    text-transform: uppercase;
}
.section-title span {
    color: var(--clr-yellow);
}
.section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;

    @include media(">mini-tablet") {
        width: 90vw;
        max-width: 90vw;
    }
}
